import React, { useEffect } from "react"
import "./eventsTopSection.scss"
import Link from "gatsby-link"
import { GatsbyImage } from "gatsby-plugin-image"
import TruncateMarkup from "react-truncate-markup"
import Card from "react-bootstrap/Card"
import Slider from "react-slick"
import ArrowRight from "../../images/learnmore/arrowRight-light.svg"
import ArrowLeft from "../../images/learnmore/arrowLeft-light.svg"

const sanitizePath = (name) => {
  return name
    .replace(/[<>:"\/\\|?*]/g, "_")
    .replace(/ /g, "-")
    .toLowerCase()
}

const EventsCards = ({ data }) => {
  const getSettings = (right, left) => {
    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: right,
      prevArrow: left,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
    return settings
  }

  let settings = getSettings(
    <img src={ArrowRight} alt="next" style={{ right: "40px !important" }} />,
    <img src={ArrowLeft} alt="prev" style={{ left: "40px !important" }} />
  )
  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  }, [])
  return (
    <div className="eventsTopSectionWrapper">
      <div className="eventsCardSection">
        <h1>Join an Event</h1>
        <p className="eventsCardSectionDesc">
          See SHAPE and the power of flourishing faster for yourself.
        </p>
        <div className="eventsCardSectionCarouselWrapper">
          <Slider {...settings}>
            {data?.map((card, index) => {
              let currEvent = card?.node
              const sanitizedPath = sanitizePath(currEvent?.name)
              return (
                <Link
                  to={`/events/${sanitizedPath}`}
                  className="eventsCardSectionCard"
                  key={currEvent.name}
                >
                  <div className="eventsCardSectionCardImg">
                    {currEvent.previewImg &&
                      currEvent.previewImg.gatsbyImageData && (
                        <GatsbyImage
                          image={currEvent.previewImg.gatsbyImageData}
                          title={currEvent.name}
                        />
                      )}
                  </div>
                  <div className="eventsCardSectionCardBody">
                    <h3>Webinar</h3>
                    <p className="eventsCardSectionCardBodyDate">
                      {currEvent?.eventDate}{" "}
                    </p>
                    <p className="eventsCardPreviewHeading">
                      {currEvent?.previewHeading}
                    </p>
                    <button>Get Started</button>
                  </div>
                </Link>
              )
            })}
          </Slider>
        </div>
      </div>
      <div className="calendlySection">
        <h1>Book a Custom Demo</h1>
        <p className="calendlySectionDesc">
          Get one-on-one guidance on how SHAPE can help your Business.{" "}
        </p>
        <div
          class="calendly-inline-widget"
          data-url="https://calendly.com/shaheryarali/30min"
          style={{ minWidth: 320, height: 700 }}
        ></div>
      </div>
    </div>
  )
}
export default EventsCards
