import React, { useState } from "react"
import "./events.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EventsCards from "../components/eventsTopSection/eventsTopSection"
import { graphql } from "gatsby"
import FlourishingCta from "../components/flourishing-cta/flourishing-cta"

const Events = ({ data }) => {
  console.log(data)
  let eventsData = data?.allContentfulEvent?.edges

  return (
    <Layout>
      <SEO title="Events" />
      <EventsCards data={eventsData} />
      <FlourishingCta page={"demo"} />
    </Layout>
  )
}

export default Events

export const pageQuery = graphql`
  query EventIndexQuery {
    allContentfulEvent(
      sort: { fields: [eventDate], order: ASC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          name
          descriptionA {
            childMarkdownRemark {
              html
            }
          }
          descriptionB {
            childMarkdownRemark {
              html
            }
          }
          bottomLine
          isActive
          eventLink
          previewImg {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          eventDate(formatString: "MMMM D, YYYY")
          previewHeading
        }
      }
    }
  }
`
